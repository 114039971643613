import { gsap } from "gsap";

document.addEventListener("DOMContentLoaded", (event) => {
    let quoteAnimationTimeline = gsap.timeline({
        // yes, we can add it to an entire timeline!
        scrollTrigger: {
            trigger: "#quote-animation-trigger",
            // pin: true, // pin the trigger element while active
            start: "top center", // when the top of the trigger hits the top of the viewport
            end: "20% center", // end after scrolling 500px beyond the start
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            markers: false,
            toggleActions: "restart reverse restart reverse",
        },
    });

    quoteAnimationTimeline.from("#frontpage-quote", {
        opacity: 0,
        scale: 0.7,
    });
});
